import React, { useRef, useState } from "react";
import { Pressable, StyleSheet, Text, View } from "@/ui/index";
import { Colors } from "@/constants";
import Popup from "reactjs-popup";

type MenuAction = {
  text: string;
  notificationText?: string;
  destructive?: boolean;
  onPress: () => void;
};

const MenuItem = ({
  index,
  action,
  menuRef,
}: {
  index: number;
  action: MenuAction;
  menuRef: any;
}) => {
  const [active, setActive] = useState("");

  const _onPress = (action: MenuAction) => {
    action.onPress();
    menuRef.current.close();
    setActive("");
  };

  if (index === 0) {
    return <input key={action.text} style={{ display: "none" }} />;
  }

  return (
    <Pressable
      key={action.text}
      onPress={() => _onPress(action)}
      onHoverIn={() => setActive(action.text)}
      onHoverOut={() => setActive("")}
      style={{
        ...s.itemContainer,
        backgroundColor: active === action.text ? Colors.hover : "transparent",
      }}
    >
      <Text
        style={{
          ...s.itemText,
          color: action.destructive ? Colors.error : Colors.title,
        }}
      >
        {action.text}
      </Text>

      {Boolean(action.notificationText) && (
        <View style={s.notifContainer}>
          <Text style={s.notifText}>{action.notificationText}</Text>
        </View>
      )}
    </Pressable>
  );
};

const Menu = ({
  actionComponent,
  actions,
  position = "right",
}: {
  actions: MenuAction[];
  actionComponent: React.ReactNode;
  position?: "left" | "right";
}) => {
  const ref = useRef<any>();

  const ActionButton = React.memo(
    React.forwardRef(({ ...props }, ref) => (
      <div ref={ref as any} {...props}>
        <div style={{ cursor: "pointer" }}>{actionComponent}</div>
      </div>
    )),
    (prevProps, nextProps) => prevProps === nextProps
  );

  const items = [{ text: "1", onPress: null }, ...actions];

  return (
    <Popup
      ref={ref}
      trigger={() => <ActionButton />}
      position={`bottom ${position}`}
      on="click"
      closeOnDocumentClick
      contentStyle={{ padding: 0, border: "none" }}
      arrow={false}
    >
      <View style={s.container}>
        {items.map((action, index) => {
          return (
            <MenuItem
              key={action.text}
              index={index}
              action={action}
              menuRef={ref}
            />
          );
        })}
      </View>
    </Popup>
  );
};

const s = StyleSheet.create({
  container: {
    minWidth: 200,
    backgroundColor: Colors.white,
    padding: 8,
    borderWidth: 1,
    borderColor: Colors.gray4,
    borderRadius: 8,
    shadowRadius: 10,
    shadowColor: "#000",
    shadowOffset: { width: -1, height: 2 },
    shadowOpacity: 0.1,
  },
  itemContainer: {
    flexDirection: "row",
    paddingHorizontal: 16,
    paddingVertical: 12,
    borderRadius: 4,
  },
  itemText: { color: Colors.title, fontSize: 16, fontWeight: "400" },
  notifContainer: {
    marginLeft: 8,
    width: 18,
    height: 18,
    borderRadius: 20,
    backgroundColor: Colors.error,
    alignItems: "center",
    justifyContent: "center",
  },
  notifText: {
    color: Colors.white,
    fontWeight: "500",
    fontSize: 10,
  },
});

export default React.memo(Menu, (prevProps, nextProps) => {
  return prevProps.actions.toString() === nextProps.actions.toString();
});
