import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Pressable,
  Image,
} from "@/ui";
import { Colors } from "@/constants";
import Icon from "./Icon";
import { useUISlice } from "@/models/useUISlice";
import DesktopContainer from "./DesktopContainer";
import MobileContainer from "./MobileContainer";
import { useRouter } from "next/router";
import { useAuth } from "@/models/auth";
import Menu from "./Menu";
import { useUserSlice } from "@/models/useUserSlice";
import { useTranslation } from "react-i18next";
import { getLangDir, getLocalizedBasePath } from "@/models/utils";

const Header = () => {
  const { t } = useTranslation();
  const isMobile = useUISlice((state) => state.isMobile);
  const router = useRouter();
  const { isAuthenticated, googleSignIn, currentUser } = useAuth();
  const isRTL = getLangDir() === "rtl";

  const _logout = () => {
    router.replace(getLocalizedBasePath());
    useUserSlice.getState().reset();
  };

  return (
    <View style={{ height: Header.HEIGHT, zIndex: 1000 }}>
      <View
        style={{
          ...s.container,
          paddingHorizontal: isMobile ? 16 : 48,
        }}
      >
        <Pressable
          onPress={
            isAuthenticated()
              ? () => router.push(`${getLocalizedBasePath()}/dashboard`)
              : () => router.push(getLocalizedBasePath())
          }
          style={{
            flexDirection: "row",
            alignItems: "center",
            height: "100%",
            justifyContent: "center",
          }}
        >
          <View style={s.logoContainer}>
            <Icon icon="logoApp" style={s.logoIcon} />
            <Text
              style={{
                ...s.logoText,
                fontSize: isMobile ? 18 : 24,
                ...(isRTL && { marginLeft: 0, marginRight: 8 }),
              }}
            >
              1Transcribe
            </Text>
          </View>
        </Pressable>

        <MobileContainer>
          <View>
            {!isAuthenticated() && (
              <TouchableOpacity
                onPress={googleSignIn}
                activeOpacity={0.8}
                style={{
                  backgroundColor: Colors.white,
                  alignItems: "center",
                  justifyContent: "center",
                  paddingHorizontal: 24,
                  borderRadius: 8,
                  height: 38,
                  borderWidth: 2,
                  borderColor: Colors.white,
                }}
              >
                <Text
                  style={{
                    fontWeight: "700",
                    color: Colors.text,
                    fontSize: 16,
                  }}
                >
                  {t("header.tryForFree")}
                </Text>
              </TouchableOpacity>
            )}

            {isAuthenticated() && (
              <Menu
                position={isRTL ? "left" : "right"}
                actions={[
                  {
                    text: t("header.dashboard"),
                    onPress: () =>
                      router.push(`${getLocalizedBasePath()}/dashboard`),
                  },
                  {
                    text: t("header.logout"),
                    destructive: true,
                    onPress: _logout,
                  },
                ]}
                actionComponent={
                  <Image
                    source={{ uri: currentUser()!.profilePictureUrl }}
                    style={{
                      width: 32,
                      height: 32,
                      borderRadius: 8,
                    }}
                  />
                }
              />
            )}
          </View>
        </MobileContainer>

        <DesktopContainer>
          {!isAuthenticated() && (
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Pressable
                onPress={googleSignIn}
                style={{
                  marginRight: 24,
                  ...(isRTL && { marginRight: 0, marginLeft: 24 }),
                }}
              >
                <Text style={s.menu}>{t("header.login")}</Text>
              </Pressable>

              <TouchableOpacity
                onPress={googleSignIn}
                activeOpacity={0.8}
                style={{
                  backgroundColor: Colors.accent,
                  alignItems: "center",
                  justifyContent: "center",
                  paddingHorizontal: 24,
                  borderRadius: 12,
                  height: 42,
                }}
              >
                <Text
                  style={{
                    fontWeight: "600",
                    color: Colors.white,
                    fontSize: 18,
                  }}
                >
                  {t("header.tryForFree")}
                </Text>
              </TouchableOpacity>
            </View>
          )}

          {isAuthenticated() && (
            <Menu
              actions={[
                {
                  text: t("header.dashboard"),
                  onPress: () =>
                    router.push(`${getLocalizedBasePath()}/dashboard`),
                },
                {
                  text: t("header.logout"),
                  destructive: true,
                  onPress: _logout,
                },
              ]}
              actionComponent={
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Image
                    source={{ uri: currentUser()!.profilePictureUrl }}
                    style={{
                      width: 28,
                      height: 28,
                      marginRight: 8,
                      borderRadius: 8,
                      ...(isRTL && { marginRight: 0, marginLeft: 8 }),
                    }}
                  />

                  <Text style={s.menu}>{currentUser()!.email}</Text>
                </View>
              }
            />
          )}
        </DesktopContainer>
      </View>
    </View>
  );
};

Header.HEIGHT = 60;

const s = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: Header.HEIGHT,
    position: "fixed" as any,
    backgroundColor: Colors.heading,
    top: 0,
    left: 0,
    right: 0,
  },
  logoContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  logoIcon: {
    width: 32,
    height: 32,
    borderRadius: 8,
  },
  logoText: {
    color: Colors.white,
    marginLeft: 8,
    fontSize: 24,
    fontWeight: "600",
  },
  menu: {
    fontSize: 18,
    fontWeight: "600",
    color: Colors.white,
  },
});

export default Header;
