import { useGoogleLogin } from "@react-oauth/google";
import * as serverClient from "@/models/serverClient";
import { useRouter } from "next/router";
import { datadogLogs } from "@datadog/browser-logs";
import { useUserSlice } from "@/models/useUserSlice";
import { User } from "@/api-lib";
import { getLocalizedBasePath } from "./utils";

export const useAuth = () => {
  const router = useRouter();

  const _redirectToDashboard = () => {
    const redirect = localStorage.getItem("redirect");

    if (redirect) {
      localStorage.removeItem("redirect");
      return router.push(redirect);
    }

    return router.push(`${getLocalizedBasePath()}/dashboard`);
  };

  const _googleLogin = useGoogleLogin({
    onSuccess: async (credentialResponse) => {
      const accessToken = credentialResponse.access_token;

      const authenticateResponse = await serverClient.authenticateAsync({
        deviceId: await serverClient.getDeviceIdAsync(),
        accessToken,
        deviceTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        deviceLanguage: navigator.language.toLowerCase(),
      });

      if (authenticateResponse.error) {
        datadogLogs.logger.error("Failed to authenticate", {
          error: authenticateResponse.error,
        });

        return;
      }

      useUserSlice
        .getState()
        .setUserToken(authenticateResponse.data?.accessToken);
      useUserSlice.getState().setUser(authenticateResponse.data?.user);
      useUserSlice
        .getState()
        .setDeviceId(authenticateResponse.data?.user.deviceId);

      _redirectToDashboard();
    },
    onError: () => {
      console.log("Login Failed");
    },
  });

  const googleSignIn = () => {
    if (window.location.host.includes("192.168")) {
      const data = {
        accessToken:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkZXZpY2VJZCI6IndlYl9lOTU4YTQ5NzE3MjBlN2Y5ODk5ZGYwMDM0OTc2NDM0YSIsImVtYWlsIjoiZGt0MjAxNDc0QGdtYWlsLmNvbSIsImV4cCI6MTc1NzM1MTg3NCwiaWF0IjoxNzI2MjQ3ODc0LCJuYmYiOjE3MjYyNDc4NzR9.55GA8SKx568go-ZlJxExrbukYJc0LxDUnvtM0KbgKyY",
        user: {
          lastTranscriptionAt: "2024-09-13T03:42:06.792Z",
          lastName: "Yarou",
          transcriptionCount: 15,
          ip: "__dev",
          createdAt: "2024-09-09T17:21:52.878Z",
          email: "dkt201474@gmail.com",
          country: "__dev",
          name: "Sadeck Yarou",
          firstName: "Sadeck",
          deviceTimezone: "America/Toronto",
          profilePictureUrl:
            "https://lh3.googleusercontent.com/a/ACg8ocKfrTBk6TLPW1o6Oifc_NriiqWIZHwyMgT9rfzC2UQZ6Oo5Lg=s96-c",
          deviceId: "web_e958a4971720e7f9899df0034976434a",
          updatedAt: "2024-09-13T03:42:06.792Z",
          usageInSeconds: 858.4399999999994,
          sk: "USER",
          pk: "web_e958a4971720e7f9899df0034976434a",
          deviceLanguage: "en-ca",
          isEmailVerified: true,
        },
      };

      useUserSlice.getState().setUserToken(data.accessToken);
      useUserSlice.getState().setUser(data.user);
      useUserSlice.getState().setDeviceId(data.user.deviceId);

      return _redirectToDashboard();
    }

    const userToken = useUserSlice.getState().userToken;

    if (userToken) {
      return _redirectToDashboard();
    }

    _googleLogin();
  };

  const currentUser = (): User | undefined => {
    const user = useUserSlice.getState().user;

    return user;
  };

  const isAuthenticated = () => {
    const user = useUserSlice.getState().user;

    return Boolean(user);
  };

  return { googleSignIn, currentUser, isAuthenticated };
};
